.ant-select-item-option-selected {
  border-left: 3px solid !important;
  border-image: linear-gradient(10deg, #6e27f8 2.1%, #20d9c0 71.42%) 3; /* Не трогать 3-ку, это ширина */
}

.ant-select-item.ant-select-item-option {
  border-left: 3px solid transparent !important;
}

.ant-select-item-option-selected:has(
    > .ant-select-item-option-content > div[aria-type='non-border']
  ) {
  background: linear-gradient(
    80.47deg,
    #18181f 6.62%,
    #2d2d3a 148.62%
  ) !important;
  border: 0 !important;
}

.ant-select-status-error:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #f16063 !important;
}

.ant-select-item.ant-select-item-option:active:has(
    > .ant-select-item-option-content > div[aria-type='non-border']
  ) {
  background: linear-gradient(
    80.47deg,
    #18181f 6.62%,
    #2d2d3a 148.62%
  ) !important;
}

.ant-select-item.ant-select-item-option:active {
  background-color: #18181f;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.ant-select-dropdown {
  border: 1px solid #474755;
}

.ant-select-arrow {
  inset-inline-end: 16px !important;
}

.non-border.ant-select-open > .ant-select-selector {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-select-dropdown:has(
    > div
      > .rc-virtual-list
      > .rc-virtual-list-holder
      > div
      > .rc-virtual-list-holder-inner
      > .ant-select-item
      > .ant-select-item-option-content
      > div[aria-type='non-border']
  ) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* CASCADER */

.cascader-custom > .ant-select-selector {
  border-radius: 8px !important;
  border-color: #474755 !important;
  background-color: #2b2b36 !important;
}

.cascader-custom > .ant-select-selector:hover {
  border-color: #fbfbff !important;
}

.cascader-custom > .ant-select-selector > .ant-select-selection-placeholder {
  color: #fbfbff !important;
}

.cascader-custom.ant-select-open
  > .ant-select-selector
  > .ant-select-selection-item {
  color: #767687 !important;
}

.ant-select-dropdown.ant-cascader-dropdown {
  background-color: #2b2b36 !important;
}

.ant-select-dropdown.ant-cascader-dropdown
  > div
  > .ant-cascader-menus
  > .ant-cascader-menu
  > .ant-cascader-menu-item.ant-cascader-menu-item-expand
  > .ant-cascader-menu-item-content {
  color: #fbfbff !important;
}
.ant-select-dropdown.ant-cascader-dropdown
  > div
  > .ant-cascader-menus
  > .ant-cascader-menu
  > .ant-cascader-menu-item {
  padding: 11px 16px !important;
}

.ant-select-dropdown.ant-cascader-dropdown
  > div
  > .ant-cascader-menus
  > .ant-cascader-menu
  > .ant-cascader-menu-item.ant-cascader-menu-item-expand
  > .ant-cascader-menu-item-expand-icon
  > span
  > svg {
  color: #fbfbff !important;
}
.ant-select-dropdown.ant-cascader-dropdown
  > div
  > .ant-cascader-menus
  > .ant-cascader-menu
  > .ant-cascader-menu-item {
  border-left: 3px solid transparent !important;
}
.ant-select-dropdown.ant-cascader-dropdown
  > div
  > .ant-cascader-menus
  > .ant-cascader-menu
  > .ant-cascader-menu-item:hover {
  background-color: #474755 !important;
}
.ant-select-dropdown.ant-cascader-dropdown
  > div
  > .ant-cascader-menus
  > .ant-cascader-menu
  > .ant-cascader-menu-item.ant-cascader-menu-item-active {
  background-color: #0e0e13 !important;
  border-left: 3px solid !important;
  border-image: linear-gradient(10deg, #6e27f8 2.1%, #20d9c0 71.42%) 3 !important;
}
.ant-select-dropdown.ant-cascader-dropdown
  > div
  > .ant-cascader-menus
  > .ant-cascader-menu
  > .ant-cascader-menu-item-active
  > div {
  font-weight: 400 !important;
  font-size: 14px !important;
}
.ant-select-dropdown.ant-cascader-dropdown
  > div
  > .ant-cascader-menus
  > .ant-cascader-menu {
  padding: 0 !important;
}
.ant-select-dropdown.ant-cascader-dropdown
  > div
  > .ant-cascader-menus
  > .ant-cascader-menu {
  border-right-color: #474755 !important;
}
