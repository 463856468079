.ant-menu > .ant-menu-item {
    width: 100% !important;
    margin: 0 !important;
    padding-left: 13px !important;
    border-left: 3px solid transparent;
    background: transparent;
}

.ant-menu-light:not(.ant-menu-horizontal).ant-menu > .ant-menu-item:not(.ant-menu-item-selected):active {

}

.ant-dropdown-menu-item-selected {
    border-left: 3px solid !important;
    border-image: linear-gradient(10deg, #6e27f8 2.1%, #20d9c0 71.42%) 3; /* Не трогать 3-ку, это ширина */
    border-radius: 4px;
}

:where(.css-dev-only-do-not-override-br05hh).ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
    padding-left: 10px !important;
}

.ant-menu-submenu-title{
    padding-left: 15px !important;
}

:where(.css-dev-only-do-not-override-1jo4mgj).ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
    /* background: transparent; */
}

.ant-menu-item:not(.ant-menu-item-selected):active {

}

.ant-menu-inline .ant-menu-submenu-title {
    margin-inline: unset !important;
    width: 100% !important;
}

.ant-layout-sider .ant-layout-sider-dark {
    height: 100% !important;
}



.ant-menu > .ant-menu-item-selected {
    border-left: 3px solid transparent;
    background: linear-gradient(0deg, #14141F, #14141F) padding-box,  linear-gradient(75.86deg, #6320E6 6.84%, #7B59FD 99.53%) border-box;
}

.ant-layout-sider-trigger {
    display: none;
    height: 0 !important;
}

.ant-layout-sider-has-trigger {
    padding-bottom: 0 !important;
}

.ant-menu-title-content {
    display: flex;
}
