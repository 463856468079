.leaflet-container {
  height: calc(100vh - 180px);
  width: calc(100vw - 240px);
  border-radius: 16px;
}

.leaflet-container:hover {
  cursor: pointer;
}

.leaflet-control-zoom {
  display: none;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-control-scale {
  right: 14px;
  bottom: 24px;
  background: transparent;
  .leaflet-control-scale-line {
      background: transparent;
      border-left: none;
      border-right: none;
  }
  .leaflet-control-scale-line:nth-child(2) {
      display: none;
  }
}