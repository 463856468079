.modalWrapper {
    height: 100vh;
    width: 100vw;
    background: rgba(29, 28, 32, 0.6) !important;
}

.modalWrapper .ant-modal-content {
    background: none !important;
    padding: 50px !important;
}

.imgWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgWrapper img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.img__date {
    position: absolute;
    bottom: 30px;
    left: 56px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.img__download {
    position: absolute;
    bottom: 38px;
    right: 83px;
    display: flex ;
    align-items: center;
    gap: 8px;
}
